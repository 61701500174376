import { Injectable } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RecycleService {

  constructor(public api: AdminService) { }


  recycleShopData(url,value){
    return this.api.getData(url, value);
  }
  
  recycleData(url,value){
    return this.api.postData(url, value);
  }
  getRecycle(url,value){
    return this.api.getData(url, value);
  }

  postData(url,value){
    return this.api.postData(url, value);
}
  

  showAlert(title:string,text:string)
  {
    swal({
      title:title,
      text:text,
      type:'error',
      showConfirmButton: false,
      timer: 1500
    })
  }

  toast(title,text)
  {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      background: '#e6e6e6',

    });
      toast({
      type: 'error',
      title: title,
      text:text
    })

  }  
  toast1(title,text)
  {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      background: '#e6e6e6',

    });
      toast({
      type: 'success',
      title: title,
      text:text
    })

  }  
  getCard(url,value){
    return this.api.getData(url, value);
  }

  putData(url, value) {
    return this.api.putData(url, value);

  }
  
}
